import * as React from "react"
import { useRef, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Slider from "react-slick"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as style from "../styles/index.module.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Index = () => {
  gsap.registerPlugin(ScrollTrigger)
  const ref = useRef(null)

  useEffect(e => {
    // const element = ref.current;

    /* mv */
    gsap.to("#mv #arrows-h1 span", {
      opacity: 1,
      top: 0,
      ease: "power4.out",
      duration: 0.5,
      delay: 0,
      scrollTrigger: {
        trigger: "#mv",
        start: "top center",
        end: "bottom top",
      },
      stagger: { from: "start", amount: 1.7 },
    })
    gsap.to("#mv", {
      backgroundPosition: "right top 10%",
      y: 10,
      ease: "power4.in",
      scrollTrigger: {
        trigger: "#mv",
        start: "bottom center",
        end: "bottom bottom",
        scrub: 1,
      },
      stagger: { from: "start", amount: 1.5 },
    })
    gsap.fromTo(
      "#mv .pl_slide",
      { opacity: 0, x: 30 },
      {
        opacity: 1,
        x: 0,
        ease: "sine",
        duration: 0.85,
        delay: 1.4,
        scrollTrigger: {
          trigger: "#mv",
          start: "top center",
          end: "bottom top",
        },
        stagger: { from: "start", amount: 0.5 },
      }
    )

    /* mind */
    gsap.to("#mind #mindImgs", {
      y: 45,
      ease: "power4.in",
      scrollTrigger: {
        trigger: "#mind",
        start: "top center",
        end: "bottom center",
        scrub: 3,
      },
    })
    gsap.to("#mind #mindItem", {
      y: 30,
      ease: "power4.in",
      scrollTrigger: {
        trigger: "#mind",
        start: "top center",
        end: "bottom center",
        scrub: 2,
      },
    })
    gsap.fromTo(
      "#mind .pl_slide",
      { opacity: 0, x: 50 },
      {
        opacity: 1,
        x: 0,
        ease: "sine",
        duration: 0.85,
        scrollTrigger: {
          trigger: "#mind",
          start: "top center",
          end: "bottom center",
        },
        stagger: { from: "start", amount: 0.5 },
      }
    )
    gsap.fromTo(
      "#mind .img01",
      { opacity: 0, y: -30 },
      {
        opacity: 1,
        y: 0,
        ease: "sine",
        duration: 0.85,
        scrollTrigger: {
          trigger: "#mind",
          start: "top center",
          end: "bottom center",
        },
        stagger: { from: "start", amount: 0.5 },
      }
    )
    gsap.fromTo(
      "#mind .img02",
      { opacity: 0, x: -30 },
      {
        opacity: 1,
        x: 0,
        ease: "sine",
        duration: 0.85,
        scrollTrigger: {
          trigger: "#mind",
          start: "top center",
          end: "bottom center",
        },
        stagger: { from: "start", amount: 0.5 },
      }
    )
    gsap.fromTo(
      "#mind .img03",
      { opacity: 0, y: 30 },
      {
        opacity: 1,
        y: 0,
        ease: "sine",
        duration: 0.85,
        scrollTrigger: {
          trigger: "#mind",
          start: "top center",
          end: "bottom center",
        },
        stagger: { from: "start", amount: 0.5 },
      }
    )

    /* service */
    gsap.fromTo(
      "#service .pl_slide",
      { opacity: 0, x: 50 },
      {
        opacity: 1,
        x: 0,
        ease: "sine",
        duration: 0.85,
        scrollTrigger: {
          trigger: "#service",
          start: "top center",
          end: "bottom center",
        },
        stagger: { from: "start", amount: 0.5 },
      }
    )
    gsap.to("#service", {
      backgroundPosition: "right top 5%",
      y: 30,
      ease: "power4.in",
      scrollTrigger: {
        trigger: "#service",
        start: "top center",
        end: "bottom center",
        scrub: 2,
      },
    })

    /* about */
    gsap.to("#about #aboutImg", {
      backgroundPosition: "50% 20%",
      y: 30,
      ease: "power4.in",
      scrollTrigger: {
        trigger: "#about",
        start: "top center",
        end: "bottom center",
        scrub: 2,
      },
      stagger: { from: "start", amount: 0.1 },
    })
    gsap.to("#about #itemAbout", {
      y: 15,
      ease: "power1.in",
      duration: 1,
      scrollTrigger: {
        trigger: "#about",
        start: "top center",
        end: "bottom center",
        scrub: 1.5,
      },
    })
    gsap.fromTo(
      "#about .pl_slide",
      { opacity: 0, x: 50 },
      {
        opacity: 1,
        x: 0,
        ease: "sine",
        duration: 0.85,
        scrollTrigger: {
          trigger: "#about",
          start: "top center",
          end: "bottom center",
        },
        stagger: { from: "start", amount: 0.5 },
      }
    )

    /* recruit */
    gsap.to("#recruit #recruitImg", {
      backgroundPosition: "50% 20%",
      y: 30,
      ease: "power4.in",
      scrollTrigger: {
        trigger: "#recruit",
        start: "top center",
        end: "bottom center",
        scrub: 2,
      },
      stagger: { from: "start", amount: 0.1 },
    })
    gsap.fromTo(
      "#recruit .pl_slide",
      { opacity: 0, x: 50 },
      {
        opacity: 1,
        x: 0,
        ease: "sine",
        duration: 0.85,
        scrollTrigger: {
          trigger: "#recruit",
          start: "top center",
          end: "bottom center",
        },
        stagger: { from: "start", amount: 0.5 },
      }
    )
    gsap.to("#recruit #itemRecruit", {
      y: 15,
      ease: "power1.in",
      duration: 1,
      scrollTrigger: {
        trigger: "#recruit",
        start: "top center",
        end: "bottom center",
        scrub: 1.5,
      },
    })
  }, [])

  // スライダー
  const settings = {
    autoplay: true,
    dots: false,
    speed: 500,
    arrows: false,
  }

  return (
    <Layout>
      <div ref={ref}>
        <Seo
          title="株式会社アローズ"
          description="求められる製品を生み出すチカラ。消費者が「欲しい」「買いたい」と思う製品をゼロから開発、消費者が求めるモノがあれば世界中から探しだす。"
        />
        <div className={style.mvWrap} id="mv">
          <h1 id="arrows-h1">
            <span>P</span>
            <span>O</span>
            <span>W</span>
            <span>E</span>
            <span>R</span> <span>T</span>
            <span>O</span> <br />
            <span>P</span>
            <span>R</span>
            <span>O</span>
            <span>D</span>
            <span>U</span>
            <span>C</span>
            <span>E</span>
          </h1>
          <p className={`pl_slide ${style.h2}`}>
            求められる製品を生み出すチカラ
          </p>
          <p className={`pl_slide ${style.catch}`}>
            日々変化する「消費者の欲望、欲しい商品」、
            <br className={style.hiddenPC} />
            そして世の中に今「現存する商品」、
            <br />
            このミスマッチにこそ我々の
            <br className={style.hiddenPC} />
            商品開発のヒントとビジネスチャンスがあります。
            <br />
            そのミスマッチを発見する情報収集力、
            <br className={style.hiddenPC} />
            商品開発力こそが我々の最大の強みです。
          </p>
          <div className={style.topicsWrap}>
            <div className={style.topicsTitle}>Topics</div>
            <div className={style.topicsItems}>
              <Slider {...settings}>
                <div className={style.item}>
                  2021年mm月dd日　
                  <br className={style.hiddenPC} />
                  採用情報を更新しました-
                </div>
                <div className={style.item}>
                  2020年mm月dd日　
                  <br className={style.hiddenPC} />
                  採用情報を更新しました
                </div>
                <div className={style.item}>
                  2019年mm月dd日　
                  <br className={style.hiddenPC} />
                  採用情報を更新しました
                </div>
              </Slider>
            </div>
          </div>
        </div>

        {/* MIND */}
        <section className={style.mind} id="mind">
          <div className={style.mindPics} id="mindImgs">
            <div className={`${style.img01} img01`}></div>
            <div className={`${style.img02} img02`}></div>
            <div className={`${style.img03} img03`}></div>
          </div>
          <div className={style.mindTxt} id="mindItem">
            <h2 id="title" className={`pl_slide`}>
              MIND
            </h2>
            <p className={`${style.catch} pl_slide`} id="catch">
              私たちができること、
              <br />
              向かっていく未来
            </p>
            <p className={`${style.txt} pl_slide`} id="txt">
              アローズはインターネットが普及する
              <br className={style.hiddenPC} />
              20年以上前から
              <br />
              通販会社に売れ筋商品を提供し続けてきました。
              <br />
              今までのノウハウを活かし、
              <br className={style.hiddenPC} />
              これからの未来をお伝えします。
            </p>
            <a href="/mind" className={`${style.toDetail} pl_slide`}>
              詳しく見る
            </a>
          </div>
        </section>

        {/* SERVICE */}
        <section className={style.service} id="service">
          <div className={style.inner}>
            <h2 id="title" className={`pl_slide`}>
              SERVICE
            </h2>
            <p className={`${style.catch} pl_slide`} id="catch">
              求められる製品を生み出す
            </p>
            <p className={`${style.txt} pl_slide`} id="txt">
              我々が得意とする、
              <br className={style.hiddenPC} />
              ユーザー目線の製品開発と発掘。
              <br />
              市場の動向をみつつ最適な製品やサービスを提供します。
            </p>
            <a href="/service" className={`${style.toDetail} pl_slide`}>
              詳しく見る
            </a>
          </div>
        </section>

        <div className={style.sectionWrap}>
          {/* ABOUT */}
          <section className={style.about} id="about">
            <div className={style.bgimg} id="aboutImg"></div>
            <div className={style.item} id="itemAbout">
              <h2 id="title" className={`pl_slide`}>
                ABOUT
              </h2>
              <p className={`${style.catch} pl_slide`} id="catch">
                アローズについて
              </p>
              <p className={`${style.txt} pl_slide`} id="txt">
                会社の方向性や未来像、
                <br />
                代表あいさつなど会社の基本情報をお伝えします。
              </p>
              <a href="/about" className={`${style.toDetail} pl_slide`}>
                詳しく見る
              </a>
            </div>
          </section>
          {/* RECRUIT */}
          <section className={style.recruit} id="recruit">
            <div className={style.bgimg} id="recruitImg"></div>
            <div className={style.item} id="itemRecruit">
              <h2 id="title" className={`pl_slide`}>
                RECRUIT
              </h2>
              <p className={`${style.catch} pl_slide`} id="catch">
                採用について
              </p>
              <p className={`${style.txt} pl_slide`} id="txt">
                会社情報だけでなく、我々の展望・そして
                <br />
                今後の未来・市場についても詳しく掲載しております。
              </p>
              <a href="/reqruit" className={`${style.toDetail} pl_slide`}>
                詳しく見る
              </a>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default Index
